import styled, { css } from "styled-components"
import { displays } from "theme/base/Variables"
import { IAlert } from "interfaces/IAlert"

const sizeAlert = {
  small: css`
    font-size: 1rem;
  `,
  meddium: css`
    font-size: 1.25rem;
  `,
  big: css`
    font-size: 2rem;
  `,
}

const AlertStyled = styled.div<IAlert>`
  ${displays.flexCenter};
  gap: 0.5rem;
  width: 100%;
  padding: 0.3rem 0;
  text-align: center;
  font-weight: 600;

  ${({ variant, size = "small", theme }) => css`
    background: ${theme.alert[variant].backgroundColor};
    color: ${theme.alert[variant].textColor};
    ${variant === "default" &&
    css`
      box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.12);
      padding: 1rem;
    `}

    ${sizeAlert[size]}
  `};

  &.transparent {
    color: #FD3A4A;
    background: transparent;
  }

  &.status-pending {
    position: relative;
  }

  & .pending-label {
    color: #ffffff;
    background: #ED1E79;
    font-size: 1.2rem;
    position: absolute;
    top: 2px;
    right: 6px;
    padding: 0 8px;
  }
`

export default AlertStyled
